import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-aw-hover-icon',
    templateUrl: './aw-hover-icon.component.html',
    styleUrls: ['./aw-hover-icon.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class AwHoverIconComponent implements AfterViewInit {

    isHovering = true;
    isInitializing = true

    @Input() label = '';
    @Input() hoverText = 'Hover Text';
    @Input() icon = '../../../assets/icons/user-list/location.svg';
    @Input() hoverIcon = '../../../assets/icons/user-list/location-white.svg';
    @Input() startColor = '#03045E';
    @Input() endColor = '#03045E';
    @Input() iconWidth = 19;
    @Input() hoverDirection: 'left' | 'right' = 'right';

    marginForLeftHover: number;

    @ViewChild('contentDiv', {static: false}) contentDiv: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.hoverDirection == 'left') {
                if (this.contentDiv && this.contentDiv.nativeElement) {
                    const contentWidth = -this.contentDiv.nativeElement.offsetWidth;
                    this.marginForLeftHover = contentWidth + 32;
                }
            }
            this.cdr.detectChanges();
            this.isHovering = false;
            this.isInitializing = false;
        }, 0)
    }

    onMouseEnter() {
        this.isHovering = true;
    }

    onMouseLeave() {
        this.isHovering = false;
    }
}