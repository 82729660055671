<div class="overflow-column" (mouseleave)="onMouseLeave()">

    <div class="name-icon"
         (mouseover)="onMouseEnter()">

        <!--Label shown next to icon-->
        <div class="label-div" [style]="isHovering && !isInitializing? 'color: transparent' : null">
            <img [style.width]="iconWidth + 'px'"
                 style="aspect-ratio: 1;"
                 [src]="icon">
            <span>{{ label }}</span>
        </div>
        <!--Code for right aligned hoverbox-->
        <div *ngIf="isHovering && hoverDirection === 'right'"
             class="aw-hover-box"
             [style.opacity]="isInitializing ? '0' : '1'"
             [style.background]="isHovering ? 'transparent linear-gradient(90deg, ' + startColor + ' 0%, ' + endColor + ' 100%)' : ''">
            <img [style.width]="iconWidth + 'px'"
                 style="aspect-ratio: 1;"
                 [src]="hoverIcon">
            <span *ngIf="isHovering">{{ hoverText }}</span>
        </div>

        <!--Code for left aligned hoverbox-->
        <div #contentDiv
             *ngIf="isHovering && hoverDirection === 'left'"
             class="aw-hover-box"
             [style.opacity]="isInitializing ? '0' : '1'"
             [style.margin-left]="isHovering && marginForLeftHover ? marginForLeftHover + 'px' : '0px'"
             [style.background]="isHovering ? 'transparent linear-gradient(90deg, ' + startColor + ' 0%, ' + endColor + ' 100%)' : ''">
            <span *ngIf="isHovering">{{ hoverText }}</span>
            <img [style.width]="iconWidth + 'px'"
                 style="aspect-ratio: 1;"
                 [src]="hoverIcon">
        </div>
    </div>
</div>